<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">


      <vs-select color="#333" class="w-full" placeholder=" انتخاب بازه زمانی ..." v-model="timeframe" >
          <vs-select-item  icon="sign" :key="index" :value="item" :text="item.title" v-for="(item,index) in timeframes.times" />
      </vs-select>
      <br>
      <p >
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="getChart()" >
            مشاهده نمودار مصرف
          </span>

        </vue-button-spinner>
      </p>
      <p>
        <img v-if="this.chart != ''"  class="m-auto md:m-0 mb-2 md:mb-0 center" :src="`data:image/png;base64,${this.chart}`" />
      </p>

    </div>


  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      isLoading: false,
      status: '',
      id: this.data.id,
      chart: '',
      timeframes : {
        'times': [
          {'id': 'hourly', 'title': 'ساعتی'},
          {'id': 'daily', 'title': 'روزانه'},
          {'id': 'monthly', 'title': 'ماهانه'},
          {'id': 'yearly', 'title': 'سالانه'}
        ]
      },
      timeframe: this.timeframe,
      url: ''
    }
  },
  methods: {
    getChart () {
      this.isLoading = true
      this.$http.get(`cloud/vm/${this.id}/traffic/chart?timeframe=${this.timeframe.id}`)
        .then(response => {
          this.chart = response.data.params.chart
          this.isLoading = false
          this.status = false // or success
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
          this.status = true //or error
        })
    }
  },
  created () {

  }
}
</script>
