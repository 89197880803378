<template>
  <div>
  <div class="row gap-5">
    <div class="block   w-full text-right mr-2">
      <router-link v-if="cloudInfo.sale_mode" to="/instance/auction/list" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست سرورها</router-link>
      <router-link v-if="!cloudInfo.sale_mode && !cloudInfo.is_docker" to="/instance/list" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست سرورها</router-link>
      <router-link v-if="cloudInfo.is_docker" :to="`/docker/info/${cloudInfo.docker_group_id}`" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به لیست سرورها</router-link>

      </div>
    <div class="bg-h-primary-light w-full  lg:w-1/4 p-5 rounded-lg border border-dashed border-white border-opacity-25 shadow-lg">
      <vira-info-card v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-info-card>

    </div>

    <div class="w-full lg:w-2/3">
      <div class="float-right hidden lg:block">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
      </div>
      <b-tabs content-class="mt-3" refs="tabs" >
        <b-tab title="ویرایش منابع" active href="resource">
          <vira-edit-card v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-edit-card>
        </b-tab>
        <!-- <b-tab v-if="cloudInfo.is_vdi == '1'" title="ورود به سرور" :active="$route.hash === '#' || $route.hash === ''" @click="tabHash('')">
          <div class="bg-h-primary-light rounded p-8 mt-8">

        <span class="block text-center">
          برای ورود به دسکتاپ مجازی ابتدا باید نرم افزار Virt Viewer را نصب داشته باشید.
        </span>
        <div class="flex gap-2 justify-center mt-6">
          <vs-button :href="VDILink" class="rounded bg-h-secondary"> دانلود گواهی ورود </vs-button>
          <vs-button href="https://www.haio.ir/app/uploads/2023/04/virt-viewer-x64-11.0-1.0.zip" target="_blank" class="rounded"> دانلود Virt Viewer </vs-button>
        </div>
      </div>
        </b-tab> -->
        <b-tab v-if="cloudInfo.is_docker != '1'" title="تغییر سیستم عامل" href="os" :active="$route.hash === '#os'" @click="tabHash('os')">
          <div class="d-block md:flex justify-center flex flex-wrap">
            <OsCard @setData="setData" :key="index" v-for="(item, index) in imageList" :osData="item" class=" mr-3 ml-4 my-base vx-col lg:w-1/5 sm:w-1/4"/>
          </div>
        </b-tab>
        <b-tab title="رویدادها" :active="$route.hash === '#events'" href="events" @click="tabHash('events')">
          <vira-tasks v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-tasks>
        </b-tab>
        <b-tab  href="console" title="کنسول" :active="$route.hash === '#console'" @click="tabHash('console')">
          <!-- <b-tab v-if="cloudInfo.location.country_iso == 'FR' || cloudInfo.location.country_iso == 'IR'" href="console" title="کنسول" :active="$route.hash === '#console'" @click="tabHash('console')"> -->
          <vira-console v-if="cloudInfo" :plan="plan" @editeCloud="editCloud" :data="cloudInfo" :vmTraffic="vm_Traffic" :key="cloudInfo.id"></vira-console>
        </b-tab>
        <b-tab v-if="cloudInfo.location.country_iso == 'IR' && cloudInfo.is_vdi !== 1 " title="نمودار ترافیک" href="trafficgraph" :active="$route.hash === '#trafficgraph'" @click="tabHash('trafficgraph')">
          <vira-traffic-monitoring v-if="cloudInfo" :plan="plan"  :data="cloudInfo"  :key="cloudInfo.id"></vira-traffic-monitoring>
        </b-tab>

        <b-tab v-if="cloudInfo.location.country_iso == 'IR' && cloudInfo.is_vdi !== 1 " title="پکیج ترافیک ماهانه" href="trafficpackage" :active="$route.hash === '#trafficpackage'" @click="tabHash('trafficpackage')">
          <ViraTrafficPackage @reloadvminfo="getInfo" v-if="cloudInfo" :plan="plan"  :data="cloudInfo"  :key="cloudInfo.id"></ViraTrafficPackage>
        </b-tab>

        <b-tab v-if="cloudInfo.location.country_iso == 'IR' && cloudInfo.is_vdi !== 1 " title="بک آپ رایگان" href="freeBackup" :active="$route.hash === '#freeBackup'" @click="tabHash('freeBackup')">
          <FreeBackup @reloadvminfo="getInfo" v-if="cloudInfo" :data="cloudInfo" :key="cloudInfo.id"></FreeBackup>
        </b-tab>

        <b-tab v-if="['IR','NL','DE','CA','FR','TR'].includes(cloudInfo.location.country_iso) && cloudInfo.is_vdi !== 1 " title="نوع بوت" href="bios" :active="$route.hash === '#bios'" @click="tabHash('bios')">
          <UEFIControl @reloadvminfo="getInfo" v-if="cloudInfo" :plan="plan"  :data="cloudInfo"  :key="cloudInfo.id"></UEFIControl>
        </b-tab>
        <b-tab v-if="cloudInfo.is_vdi !== 1 " title="Rescue Mode" href="rescue-mode" :active="$route.hash === '#rescue-mode'" @click="tabHash('rescue-mode')">
          <RescueMode @reloadvminfo="getInfo" v-if="cloudInfo" :plan="plan"  :data="cloudInfo"  :key="cloudInfo.id"></RescueMode>
        </b-tab>
        <span v-if="cloudInfo.sale_mode">
        <b-tab v-if="cloudInfo.networks[0].detail.ports[0] " title="پورتهای اختصاص یافته" href="ports" :active="$route.hash === '#ports'" @click="tabHash('ports')">

            <!-- <div :key="port" v-for="(port) in cloudInfo.networks[0].detail.ports" > -->
              <p>
                دامنه جهت اتصال:
                <strong class="text-success">{{ cloudInfo.proxy_domain.toLowerCase() }}</strong>
              </p>
              <p  v-if="cloudInfo.os_type === 'Unix'">

                 پورت اتصال (SSH):
                <strong class="text-success">{{ cloudInfo.networks[0].detail.ports[0].SSH }}</strong>

              </p>

              <p v-else>
                پورت اتصال (RDP):
                <strong class="text-success">{{ cloudInfo.networks[0].detail.ports[1].RDP }}</strong>
              </p>

              <p>
                رنج پورت قابل استفاده =>
                <strong class="text-success">از پورت {{ cloudInfo.networks[0].detail.ports[2].Port_Range.split(":")[0] }} تا {{ cloudInfo.networks[0].detail.ports[2].Port_Range.split(":")[1] }}</strong>
              </p>

            <!-- </div> -->


          <!-- {{ cloudInfo.networks[0].detail.ports }} -->

        </b-tab>
      </span>
      </b-tabs>
      </div>

  </div>

      <div>
      <vs-popup :active.sync="reinstallPrompt" title="نصب مجدد">
        <div  class="con-exemple-prompt">
          <div v-if="smsConfirmStatus">
              <p>کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.</p>
              <vs-input
                class="w-full mb-3"
                v-model="requestCode"
                label-placeholder="کد تایید"
              ></vs-input>
          </div>
          <div v-else>
            <p>آیا از نصب مجدد سیستم عامل  اطمینان دارید </p>
          </div>
          <vs-button
            icon=""
            class="float-right rounded w-25"
            color="primary"
            @click="reinstall()"
            >نصب مجدد</vs-button
          >
        </div>
      </vs-popup>
    </div>

</div>

</template>

<script>
import ViraInfoCard     from './ViraInfoCard.vue'
import ViraEditCard     from './ViraEdit.vue'
import ViraTasks     from './ViraTasks.vue'
import ViraConsole     from './ViraConsole.vue'
import FloatIp          from './FloatIp.vue'
import EditVm           from './EditVm.vue'
import Resource         from './Resource.vue'
import OsCard           from '../../OsCard.vue'
// import Firewall         from './Firewall.vue'
import ViraTrafficMonitoring     from './ViraTrafficMonitoring.vue'
import UEFIControl     from './UEFIControl.vue'
import FreeBackup     from './FreeBackup.vue'
import RescueMode     from './RescueMode.vue'
import ViraTrafficPackage     from './ViraTrafficPackage.vue'

// import TerminalAccess   from './TerminalAccess.vue'

export default {
  components : {
    FreeBackup,
    ViraTrafficPackage,
    FloatIp,
    ViraInfoCard,
    ViraEditCard,
    ViraTasks,
    ViraConsole,
    EditVm,
    Resource,
    OsCard,
    UEFIControl,
    RescueMode,
    // Firewall,
    ViraTrafficMonitoring
    // TerminalAccess
  },
  data () {
    return {
      cloudId : '',
      cloudInfo : {},
      vm_Traffic: '',
      plan : '',
      imageList : [],
      imageInfo  : null,
      requestCode : '',
      reinstallPrompt : false,
      VDILink:'',
      myloc: ''
    }
  },
  watch : {
    $route () {
      this.cloudId = this.$route.params.id
      this.getInfo()
    }
  },
  computed: {
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }

  },
  methods : {
    tabHash (val) {
      location.hash = val
    },
    editCloud (val) {
      const data = {
        // id : this.cloudId,
        vm_title: val.title,
        vm_vcpu : val.cpu,
        vm_ram : val.ram,
        vm_extra_hard : val.extra_hard
      }
      this.$http.put(`/cloud/vm/${this.cloudId}`, data).then((result) => {
        this.$vs.notify({text:result.data.message})
        this.getInfo()
      }).catch((err) => {
        this.$vs.notify({text: err.response.data.message, color: 'danger'})
      })
    },
    getVMTraffic () {
      this.$http.get(`/cloud/vm/${this.cloudId}/traffic/current`).then((result) => {
        this.vm_Traffic = result.data.params[0]


      }).catch(() => {

      })

    },
    getInfo () {
      this.$http.get(`/cloud/vm/${this.cloudId}`).then((result) => {
        this.cloudInfo = result.data.params
        this.getImageList()
      }).catch(() => {

      })
    },
    getPlan () {
      this.$http.get('/cloud/plan').then((result) => {
        this.plan = result.params.data[0]
      }).catch(() => {

      })
    },
    getImageList () {
      this.$vs.loading()
      this.$http
        .get(`/cloud/image/template?type=${this.cloudInfo.vm_type}&node_id=${this.cloudInfo.node_id}`)
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params.data
          this.imageList = data
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'dager',
            title: err.response.data.message
          })
        })
    },
    reInstallRequest () {
      this.$http
        .get(`/cloud/vm/${this.cloudId}/reinstall/request`).then((res) => {
          this.$vs.notify({
            color: 'success',
            text: res.data.message
          })
        }).catch((err) => {
          this.$vs.notify({
            color: 'danger',
            text: err.response.data.message
          })
        })
    },
    reinstall () {

      const data =  {
        vm_id: this.cloudId * 1,
        confirm_code: this.requestCode,
        template_id: this.imageInfo.id,
        template_version: this.imageInfo.versions
      }

      this.$http.post('cloud/vm/reinstall', data).then((res) => {

        this.$vs.notify({
          color: 'success',
          text: res.data.message
        })
        this.$router.go(-1)
        this.reinstallPrompt = false
      }).catch((err) => {
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message
        })
      }).finally(() => {

        this.requestCode = ''
      })
    },
    setData (value) {
      this.imageInfo = value
      if (value.id && value.versions) {
        this.reinstallPrompt = true
        if (this.smsConfirmStatus === 1) {
          this.reInstallRequest()
        }

      } else {
        this.$vs.notify({
          text: 'لطفا سیستم عامل و نسخه را انتخاب کنید',
          color : 'warning'
        })
      }
    },
    getVDILink (id) {
      this.$http.get(`/cloud/vm/vdi/${id}`)
        .then((response) => {
          this.VDILink = response.data.params.url
        })
    }

  },
  created () {
    this.cloudId = this.$route.params.id
    this.getInfo()
    if (this.is_vdi === 1) {
      this.getVDILink(this.cloudId)
    }
    this.is_vdi = this.is_vdi
    this.getVMTraffic()
  }
}
</script>
