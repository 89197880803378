<template>
  <div>


        <div class="vx-row justify-center">
          <div class="flex flex-col m-base">
            <round-slider
              style="direction: ltr"
              v-model="hard"
              ref="slid"
              :min="min_hard"
              :max="returnBigger(hard,max_hard)"
              :change="checkVal"
              step="5"
              start-angle="315"
              end-angle="+270"
              radius="90"
              />
            <vs-chip class="primary-color text-white" >HDD / GB</vs-chip>
          </div>

          <div class="flex flex-col m-base">
            <round-slider
              style="direction: ltr"
              v-model="ram"
              :min="ram_min"
              :max="ram_max"
              step="1"
              start-angle="315"
              end-angle="+270"
              radius="90"
            />
            <vs-chip class="primary-color text-white">RAM / GB</vs-chip>
          </div>
          <div class="flex flex-col m-base">
            <round-slider
              style="direction: ltr"
              v-model="cpu"
              :min="min_cpu"
              :max="max_cpu"
              step="1"
              start-angle="315"
              end-angle="+270"
              radius="90"
            />
            <vs-chip class="primary-color text-white">CPU / CORE</vs-chip>
          </div>
        </div>

        <div class="vx-row">
          <div
            class="
              vx-col
              w-full
              d-theme-border-grey-light
              mb-6
              flex
            "
          ></div>
          <div class="flex text-left justify-end w-full">

              <vs-button
                @click="update"
                class="px-4 rounded-lg py-3 mr-3 bg-h-primary-light w-full md:w-1/4 hover:bg-h-primary-light border-0 border-b-4 border-solid border-h-secondary hover:border-white focus:bg-h-info" :disabled="dis"
                >

                <template v-if="updateBtnText == true" >
                  <feather-icon icon="RefreshCwIcon" class="w-6 rotate -mb-2 block" :class="{ reload: rotation }" />
                </template>
                <template v-else>
                  ویرایش منابع سرور
                </template>

          </vs-button>
          </div>
        </div>

  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
import { VsIcon } from 'vuesax'


export default {
  components: {
    RoundSlider,
    VsIcon
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      cpu: this.data.vm_vcpu,
      hard: +this.data.vm_hard,
      ram: this.data.vm_ram,
      min_hard: this.data.vm_hard,
      max_hard: this.data.template_hw_requirement.max_hard,
      ram_max: this.data.template_hw_requirement.max_ram,
      ram_min: this.data.template_hw_requirement.min_ram,
      min_cpu: this.data.template_hw_requirement.min_vcpu,
      max_cpu: this.data.template_hw_requirement.max_vcpu,
      vm_number: this.data.vm_sequence_id,
      title: { name: this.data.vm_title, edit: false },
      tasksParams: {},
      tasks: [],
      currentPage: 1,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'method_name', label: 'Method Name' },
        { key: 'status', label: 'Status' },
        { key: 'details', label: 'Details' },
        { key: 'created_at', label: 'Created At' }
      ],
      updateBtnText: false,
      rotation: false,
      dis: false
    }
  },
  computed: {
    // price () {
    //   const price =
    //     this.bandwidth_price +
    //     (this.cpu * this.plan.vcpu_price +
    //       this.ram * this.plan.ram_price +
    //       this.hard * this.plan.hard_price)
    //   console.log()
    //   return price
    // }
  },
  watch: {
    data () {
      this.cpu = this.data.vm_vcpu
      this.hard = this.data.vm_hard
      this.ram = this.data.vm_ram
      this.title.name = this.data.vm_title
    }

  },
  methods: {
    getTasks () {
      this.$http.get(`cloud/vm/${this.vm_number}/tasks?page=${this.currentPage}`)
        .then(response => {
          this.tasksParams = response.data.params
          this.tasks = response.data.params.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    returnBigger (num, max) {
      if (num > max) {
        return num
      } else {
        return max
      }
    },
    checkVal (c) {
      // console.log(c.value)
      if (+c.value < +this.hard) {
        c.value = 100
      }
    },
    update () {
      this.dis = true
      this.updateBtnText = true
      this.rotation = true
      setTimeout(() => {
        this.rotation = false
      }, 100)
      const emitData = {
        title: this.title.name,
        ram: this.ram,
        cpu: this.cpu,
        extra_hard: (this.hard * 1) - this.data.vm_hard || ''
      }
      this.$emit('editeCloud', emitData)
      setTimeout(() => {
        this.dis = false
        this.updateBtnText = false
        this.rotation = false
      }, 5000)
    },
    editTodo (title) {
      this.editedTodo = title
    },

    linux (d) {
      if (d === 'Windows') {
        return 'win10'
      }
      return d.toLowerCase()
    }
  },
  created () {
    this.getTasks()
  }
}
</script>

<style>
tbody tr:nth-child(odd){
  background-color: #090C2B;
  color: #fff;
}
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-link {
    color: #1791da !important;
    margin-right: 10px;
    border: 1px dashed #ffffff78 !important;
    border-radius: 8px !important;
}
.nav-tabs .nav-link:hover {
  color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
[dir] .nav-tabs .nav-link.active, [dir] .nav-tabs .nav-item.show .nav-link {
    background-color: unset !important;

    color: white !important;
    border-radius: 8px !important;
    border: 1px dashed #1791da !important;
}
.rotate {
    -moz-transition: all 5s linear;
    -webkit-transition: all 5s linear;
    transition: all 5s linear;
}
.rotate.reload {
    -moz-transform:rotate(360deg);
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}

</style>
