<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">

      <div class="mb-5">خرید یا رزرو پکیج ترافیک :</div>

      <vs-select color="#333" class="w-full" placeholder=" انتخاب پکیج ترافیک ... " v-model="trafficPackageVmID" >
        <vs-select-item icon="sign" :key="index" :value="item.id" :text="item.title +' - قیمت: '+ (item.total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) + ' تومان'" v-for="(item,index) in trafficPackagesParams" />
      </vs-select>

      <br>
      <p>
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
        >

          <span @click="buyTrafficPackagePrompt()" >
            خرید پکیج ترافیک
          </span>

        </vue-button-spinner>
      </p>

      <table v-if="trafficPackages && trafficPackages.length > 0" class="w-full text-sm text-left  text-grey rounded-t">
        <thead class="text-xs text-grey ">
        <tr class="border  border-white border-opacity-25">
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">شناسه</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">عنوان</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">استفاده شده (GB)</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">درصد استفاده</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">زمان انقضاء</th>
          <th scope="col" class="border border-white border-opacity-25 px-6 py-3">وضعیت</th>
        </tr>
        </thead>
        <tbody class="">
        <tr class="border border-white border-opacity-25" v-for="item in trafficPackages" :key="item.id">
          <th scope="row" class="px-6 py-4 font-medium text-grey whitespace-nowrap">{{ item.id }}</th>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.traffic_package.title }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.total_traffic_usage }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.usage_percent }} %</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{ item.expired_at }}</td>
          <td class="border border-white border-opacity-25 px-6 py-4">{{
              (item.is_over == 1) ? "پایان یافته" : "فعال"
            }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div>
      <vs-popup :active.sync="buyPackagePrompt" title="تایید خرید پکیج ترافیک">
        <div class="con-exemple-prompt">
          <div>
            <p>آیا از خرید پکیج ترافیک زیر اطمینان دارید؟ </p>
            <p>{{ this.trafficPackageVmItem.title + ' به قیمت ' + (this.trafficPackageVmItem.total_price ? this.trafficPackageVmItem.total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '') + ' تومان' }}</p>
          </div>
          <vs-button
            icon=""
            class="float-right rounded"
            color="success"
            @click="buyTrafficPackage()"
          >بله</vs-button>
        </div>
      </vs-popup>
    </div>

  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data', 'plan', 'rotation'],
  data () {
    return {
      id: this.data.id,
      isLoading: false,
      buyPackagePrompt: false,
      status: '',
      trafficPackageVmID: '',
      trafficPackageVmItem: '',
      trafficPackagesParams: [],
      trafficPackages: this.data.traffic_package
    }
  },
  methods: {
    getTrafficPackages () {
      this.$http.get(`cloud/traffic/packages?datacenter_id=${this.data.datacenter.id}`)
        .then(response => {
          this.trafficPackagesParams = response.data.params.data
        })
        .catch(() => {
          // console.error(error)
        })
    },
    buyTrafficPackagePrompt () {
      if (this.trafficPackageVmID) {
        this.trafficPackageVmItem = this.trafficPackagesParams.find(
          (item) => item.id === this.trafficPackageVmID
        )
        this.buyPackagePrompt = true
      } else {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: 'لطفاً ابتدا یک پکیج ترافیک را انتخاب نمایید.'
        })
      }
    },
    buyTrafficPackage () {
      this.buyPackagePrompt = false
      if (this.trafficPackageVmID) {
        this.isLoading = true
        this.$http.post(`cloud/vm/${this.id}/traffic/package`, {traffic_package_id: this.trafficPackageVmID}).then((result) => {

          this.isLoading = false
          this.status = false // or success
          this.trafficPackageVmID = ''
          this.$vs.notify({
            color: 'success',
            title: ' عملیات موفق',
            text: result.data.message
          })
          this.getInfo()

        }).catch((err) => {
          this.isLoading = false
          this.status = true //or error
          this.$vs.notify({
            color: 'danger',
            title: ' خطا',
            text: err.response.data.message
          })
        })
      } else {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: 'لطفاً ابتدا یک پکیج ترافیک را انتخاب نمایید.'
        })
      }
    },
    getInfo () {
      this.$http.get(`/cloud/vm/${this.id}`).then((result) => {
        this.trafficPackages = result.data.params.traffic_package
      }).catch(() => {

      })
    }
  },
  created () {
    this.getTrafficPackages()
  },
  mounted () {
    this.trafficPackages = this.data.traffic_package
  }
}
</script>

