<template>
    <div >
        <div style="flex-direction: column" class="cursor-pointer justify-between flex lg:flex-row md:flex-row">
        <b-form-radio-group
            v-model="timeFrame"
            :options="options"
            class="mb-3 cursor-pointer"
            value-field="item"
            text-field="name"
            ></b-form-radio-group>
          <b-button @click="getReport" size="sm" class="mx-5 float-left">
              به روز رسانی
             <b-icon icon="arrow-repeat">
             </b-icon>
         </b-button>
        </div>
        <!-- pic -->
        <div class="row justify-center">
            <div v-for="(item,index) in img" :key="index">
                <span>{{index}}</span>
              <img width="100%"   :src="`data:image/png;base64,${item}`" alt="Red dot" />

            </div>
        </div>
    </div>
</template>
<script>
export default {
  props : ['info'],
  data () {
    return {
      timeFrame : 'hour',
      vmId : '',
      img : {},
      options: [
        { item: 'hour', name: 'ساعتی' },
        { item: 'day', name: 'روزانه' },
        { item: 'week', name: ' هفتگی' },
        { item: 'month', name: 'ماهانه' },
        { item: 'year', name: 'سالانه' }

      ]
    }
  },
  watch : {
    timeFrame () {
      this.getReport()
    }
  },
  methods : {
    getReport () {
      // this.$vs.loading()
      this.$http.post('/vira/cloud/vm/report', {vm_id: this.vmId, time_frame : this.timeFrame}).then((result) => {
        // this.$vs.loading.close()
        this.img = result.data.params.data

      }).catch(() => {
        // this.$vs.loading.close()
      })
    }
  },
  created () {
    this.vmId = this.info.id
    this.getReport()
  }
}
</script>
