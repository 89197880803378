<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">


      <span @click="getConsole()" class="btn btn-primary p-4 block w-auto mb-4">
        درخواست اتصال کنسول
      </span>


      <a v-if="url" :href="url.url" target="_blank" class="btn btn-secondary block ">
        ورود به کنسول
      </a>
    </div>


  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'

export default {
  components: {
    RoundSlider
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      id: this.data.id,
      url: ''
    }
  },
  methods: {
    getConsole () {
      this.$http.get(`cloud/vm/remote/${this.id}`)
        .then(response => {
          this.url = response.data.params
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color: 'danger'
          })
        })
    }
  },
  created () {

  }
}
</script>

<style>
tbody tr:nth-child(odd) {
  background-color: #090C2B;
  color: #fff;
}

.nav-tabs {
  border: 0;
}

.nav-tabs .nav-link {
  color: #1791da !important;
  margin-right: 10px;
  border: 1px dashed #ffffff78 !important;
  border-radius: 8px !important;
}

.nav-tabs .nav-link:hover {
  color: white !important;
  border-radius: 8px !important;
  border: 1px dashed #1791da !important;
}

[dir] .nav-tabs .nav-link.active,
[dir] .nav-tabs .nav-item.show .nav-link {
  background-color: unset !important;

  color: white !important;
  border-radius: 8px !important;
  border: 1px dashed #1791da !important;
}
</style>
