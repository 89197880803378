<template>
  <div>
    <b-button @click="addPrompt = true" class="mb-3 primary-color"
      >افزودن دیسک</b-button
    >
    <b-table class="text-center" :items="info.volume_object" :fields="fields">
      <template #cell(op)="row">
        <div>
          <b-button
            class="border-0"
            @click="setdeletePrompt(row.item)"
            variant="outline-danger"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
          <b-button
            class="border-0"
            @click="setEditPrompt(row.item)"
            variant="outline-warning"
          >
            <b-icon icon="pencil-square"></b-icon>
          </b-button>
        </div>
      </template>
    </b-table>

    <!-- add volume popup -->
    <div class="mb-4">
      <vs-popup :active.sync="addPrompt" title=" افزودن دیسک">
        <div>
          <vs-input
            class="w-full mb-4"
            label="عنوان دیسک :"
            v-model="volumeLabel"
          ></vs-input>
          <vs-input
            dir="ltr"
            class="w-full mb-4"
            label="مسیر دیسک :"
            placeholder="/dev/.../"
            v-model="volumePath"
          ></vs-input>
          <vs-input-number
            class="mt-4"
            label="حجم (گیگ)"
            :step="5"
            :min="5"
            :max="100"
            v-model="volumeSize"
          />
        </div>
        <vs-button icon="add" class="rounded  mt-3" @click="add()"
          >افزودن</vs-button
        >
      </vs-popup>
      <!-- delete prompt -->
      <div>
        <vs-popup :active.sync="deletePrompt" title="حذف هایو ابر">
          <div class="con-exemple-prompt">
            <div v-if="smsConfirmStatus">
              <p>
                کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.
              </p>
              <vs-input
                class="w-full mb-3"
                v-model="requestCode"
                label-placeholder="کد تایید"
              ></vs-input>
            </div>
            <div v-else>
              <p>آیا از حذف اطمینان دارید؟ </p>
            </div>
            <vs-button
              icon="delete"
              class="float-right rounded w-25"
              color="danger"
              @click="remove"
              >حذف</vs-button
            >
          </div>
        </vs-popup>
      </div>

      <!-- edit promtp -->
      <div>
        <vs-popup :active.sync="editPrompt" title=" ویرایش دیسک ">
          <div>
            <vs-input
              class="w-full my-4"
              label="عنوان دیسک :"
              v-model="volumeLabel"
            ></vs-input>
            <vs-input
              dir="ltr"
              class="w-full my-4"
              label="مسیر دیسک :"
              placeholder="/dev/.../"
              v-model="volumePath"
            ></vs-input>
            <div class="w-full flex justify-center">
            <vs-chip class="w-50 ">حجم فعلی : <span>{{volumeSize}}</span></vs-chip>
            </div>
            <br>
            <vs-input-number
              class="my-4"
              label="حجم اضافه (گیگ)"
              :step="5"
              :min="5"
              :max="100"
              v-model="extraVolume"
            />
          </div>
          <vs-button icon="edit" class="rounded  mt-5" @click="edit()"
            >اعمال تغییرات</vs-button
          >
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      extraVolume : 0,
      editPrompt : false,
      volumeId: '',
      deletePrompt: false,
      addPrompt: false,
      volumeSize: 5,
      volumeLabel: '',
      volumePath: '',
      fields: [
        { key: 'volume_label', label: 'عنوان' },
        { key: 'volume_path', label: 'مسیر' },
        { key: 'volume_size', label: 'حجم(گیگ)' },
        { key: 'created_at', label: 'تاریخ ساخت' },
        { key: 'op', label: 'عملیات' }
      ],
      requestCode: ''
    }
  },
  computed : {
    smsConfirmStatus () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    }
  },
  methods: {
    setEditPrompt (row) {
      this.editPrompt = true
      this.volumeId = row.id
      this.volumeLabel = row.volume_label
      this.volumePath = row.volume_path
      this.volumeSize = row.volume_size
    },
    setdeletePrompt (d) {
      this.volumeId = d.id
      this.deletePrompt = true
      if (this.smsConfirmStatus === 1) {
        this.removeRequestCode()
      }


    },

    add () {
      const data = {
        vm_id: this.info.id,
        volume_label: this.volumeLabel,
        volume_size: this.volumeSize,
        volume_path: this.volumePath
      }
      this.$http
        .post('/vira/cloud/vm/volume/add', data)
        .then(result => {
          this.$vs.notify({
            text: result.data.message,
            color: 'success'
          })
          this.$emit('getInfoAgain')
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
    },
    remove () {
      this.$http
        .post('/vira/cloud/vm/volume/delete', {
          volume_id: this.volumeId,
          confirm_code: this.requestCode
        })
        .then(result => {
          this.$vs.notify({
            text: result.data.message,
            color: 'success'
          })
          this.$emit('getInfoAgain')
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
    },
    edit () {
      const data = {
        volume_id : this.volumeId,
        volume_lable : this.volumeLabel,
        extra_hard : this.extraVolume
      }
      this.$http.post('/vira/cloud/vm/volume/resize', data).then((res) => {
        this.$vs.notify({
          text: res.data.message,
          color: 'success'
        })
      }).catch((err) => {
        this.$vs.notify({
          text: err.response.data.message,
          color: 'danger'
        })
      })
    },
    removeRequestCode () {
      this.$http
        .post('/vira/cloud/vm/volume/delete/request', {
          volume_id: this.volumeId
        })
        .then(result => {
          this.$vs.notify({
            text: result.data.message,
            color: 'success'
          })
          this.$emit('getInfoAgain')
          this.$emit('getInfoAgain')
        })
        .catch(err => {
          this.$vs.notify({
            text: err.response.data.message,
            color: 'danger'
          })
        })
    }
  },
  created () {}
}
</script>
