<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">
      <p>
      وضعیت ریسک مود : <span v-if="data.rescue_mode">فعال</span><span v-else>غیر فعال</span>
      </p>

       <br>
      <p >
        <span v-if="data.rescue_mode != 1">
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >

          <span @click="attachIso()" >
            فعال سازی ریسک مود
          </span>

        </vue-button-spinner>

      </span>
      <span v-else>
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="detachIso()" >
            غیر فعال کردن ریسک مود
          </span>
        </vue-button-spinner>
      </span>
      </p>
      <p class="text-success">
      با تغییر وضعیت ریسک مود سرور ریبوت می‌شود <br>
      پس از فعال‌سازی ریسک مود از طریق کنسول می‌توانید اقدامات مورد نظر را انجام دهید

      </p>

      <br>
      <!-- <p>
        <b-button class="button bg-warning" href="https://doc.haio.ir/cloud-server/instance-details#%D9%86%D9%88%D8%B9-%D8%A8%D9%88%D8%AA" target="_blank">
        تغییر نوع بوت به چه کار من می‌آید؟
        </b-button>
      </p> -->

    </div>


  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      isLoading: false,
      status: '',
      id: this.data.id,
      url: '',
      biosMode: '',
      bootTypes : [
        {'id': '1', 'title': 'Legacy BIOS'},
        {'id': '0', 'title': 'UEFI'}
      ]
    }
  },
  methods: {
    // getChart () {
    //   this.isLoading = true
    //   this.$http.get(`cloud/vm/${this.id}/traffic/chart?timeframe=${this.timeframe.id}`)
    //     .then(response => {
    //       this.chart = response.data.params.chart
    //       this.isLoading = false
    //       this.status = false // or success
    //     })
    //     .catch(error => {
    //       console.error(error)
    //       this.isLoading = false
    //       this.status = true //or error
    //     })
    // }
    attachIso () {
      this.isLoading = true
      this.$http.put(`cloud/vm/${this.id}/rescue/mode/attach`).then((result) => {

        this.img = result.data.params.data
        this.isLoading = false
        this.status = false // or success
        this.$vs.notify({
          color: 'success',
          title: ' عملیات موفق',
          text: result.data.message
        })
        this.$emit('reloadvminfo')

      }).catch((err) => {
        this.isLoading = false
        this.status = true //or error
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      })
    },
    detachIso () {
      this.isLoading = true
      this.$http.put(`cloud/vm/${this.id}/rescue/mode/detach`).then((result) => {

        this.img = result.data.params.data
        this.isLoading = false
        this.status = false // or success
        this.$vs.notify({
          color: 'success',
          title: ' عملیات موفق',
          text: result.data.message
        })
        this.$emit('reloadvminfo')

      }).catch((err) => {
        this.isLoading = false
        this.status = true //or error
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      })
    }
  },
  created () {

  }
}
</script>
<style scoped>
.biosTypes :disabled {
  background: grey;
}


</style>
