<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">
      <p>
        نوع بوت کنونی: {{ data.bios_mode_text }}
      </p>

      <vs-select :selected="this.currentBootType" color="#333" class="w-full" placeholder=" تغییر بوت به ... " v-model="biosMode" >
          <vs-select-item class="biosTypes" icon="sign" :disabled="item.id == data.bios_mode ? true :  false" :key="index" :value="item.id" :text="item.title" v-for="(item,index) in bootTypes" />
       </vs-select>

       <br>
      <p >
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >

          <span @click="changeBiosType()" >
            تغییر بوت
          </span>

        </vue-button-spinner>
      </p>
      <p class="text-danger">
        با تغییر نوع بوت سرور شما ریبوت می‌شود.

      </p>
      <br>
      <p>
        <b-button class="button bg-warning" href="https://doc.haio.ir/cloud-server/instance-details#%D9%86%D9%88%D8%B9-%D8%A8%D9%88%D8%AA" target="_blank">
        تغییر نوع بوت به چه کار من می‌آید؟
        </b-button>
      </p>

    </div>


  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data', 'plan', 'vmTraffic'],
  data () {
    return {
      isLoading: false,
      status: '',
      id: this.data.id,
      url: '',
      biosMode: '',
      currentBootType: '',
      bootTypes : [
        {'id': '1', 'title': 'Legacy BIOS'},
        {'id': '0', 'title': 'UEFI'}
      ]
    }
  },
  methods: {
    // getChart () {
    //   this.isLoading = true
    //   this.$http.get(`cloud/vm/${this.id}/traffic/chart?timeframe=${this.timeframe.id}`)
    //     .then(response => {
    //       this.chart = response.data.params.chart
    //       this.isLoading = false
    //       this.status = false // or success
    //     })
    //     .catch(error => {
    //       console.error(error)
    //       this.isLoading = false
    //       this.status = true //or error
    //     })
    // }
    changeBiosType () {
      this.isLoading = true
      this.$http.put(`cloud/vm/${this.id}/change/bios`, {bios_mode: this.biosMode}).then((result) => {

        this.img = result.data.params.data
        this.isLoading = false
        this.status = false // or success
        this.$vs.notify({
          color: 'success',
          title: ' عملیات موفق',
          text: result.data.message
        })
        this.$emit('reloadvminfo')

      }).catch((err) => {
        this.isLoading = false
        this.status = true //or error
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      })
    }
  },
  created () {

  }
}
</script>
<style scoped>
.biosTypes :disabled {
  background: grey;
}


</style>
