<template>
  <div>

    <div class="relative overflow-x-auto shadow-md rounded-lg bg-h-primary-light p-5">
      <p>
        بک آپ رایگان: {{ data.free_backup ? "فعال" : "غیر فعال" }}
      </p>

      <vs-select :selected="this.currentFreeBackupType" color="#333" class="w-full" placeholder=" تغییر به ... " v-model="freeBackupMode" >
          <vs-select-item class="biosTypes" icon="sign" :disabled="item.id == data.free_backup ? true :  false" :key="index" :value="item.id" :text="item.title" v-for="(item,index) in freeBackupTypes" />
       </vs-select>

       <br>
      <p >
        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >

          <span @click="changeFreeBackup()" >
            ذخیره
          </span>

        </vue-button-spinner>
      </p>


      <br>
      <span v-if="data.free_backup">
        <p>
          تاریخ آخرین بک آپ:
        </p>
        <p>
          {{ (data.last_backup_at ? data.last_backup_at.replace(/ /g," - ساعت ") : "") }}
        </p>
        <br>
      </span>

    </div>


  </div>
</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'

export default {
  components: {
    // RoundSlider
    VueButtonSpinner
  },
  props: ['data'],
  data () {
    return {
      isLoading: false,
      status: '',
      id: this.data.id,
      freeBackupMode: '',
      currentFreeBackupType: '',
      freeBackupTypes : [
        {'id': '1', 'title': 'فعال'},
        {'id': '0', 'title': 'غیر فعال'}
      ]
    }
  },
  methods: {
    changeFreeBackup () {
      this.isLoading = true
      this.$http.put(`cloud/vm/${this.id}/change/backup`, {free_backup: this.freeBackupMode}).then((result) => {

        this.isLoading = false
        this.status = false // or success
        this.$vs.notify({
          color: 'success',
          title: ' عملیات موفق',
          text: result.data.message
        })
        this.$emit('reloadvminfo')

      }).catch((err) => {
        this.isLoading = false
        this.status = true //or error
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      })
    }
  },
  created () {

  }
}
</script>
<style scoped>
.biosTypes :disabled {
  background: grey;
}
</style>
